import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from './Icon';

export const Footer = () => {
  return (
    <div className="border-t py-7 text-12 md:py-10">
      <div className="mx-auto grid w-full max-w-screen-xl grid-cols-1 space-y-4 px-5 md:grid-cols-2">
        <div className="col-span-2 flex flex-col justify-between space-y-6 md:flex-row md:items-center md:space-y-0">
          <p className="flex items-center">
            <Icon.FooterLogo className="" />
          </p>
          <div className="divide-x-gray-200 flex items-center divide-x">
            {/* <div>
              <a
                href="https://atom-produce-f4f.notion.site/b31d164a5f73491e84df5ecf80858ea2"
                target="_blank"
              >
                이용약관
              </a>
            </div> */}
            <div>
              <a
                href="https://atom-produce-f4f.notion.site/d05a098478fd44c0810434e50e4442eb"
                target="_blank"
                className="pr-5 md:pr-10"
              >
                개인정보처리방침
              </a>
            </div>
            <div>
              <a
                href="https://www.notion.so/952a87505030478e8d33569898c65028"
                target="_blank"
                className="px-5 md:px-10"
              >
                FAQ
              </a>
            </div>
            <div className="px-5 md:px-10">
              <Link to="/notice">공지사항</Link>
            </div>
          </div>
        </div>

        <div>
          <p className="mt-5 text-gray-800">(주) 원드림</p>
          <p>
            사업자등록번호 : 629-81-012-79 | 대표자 : 김기연 <br />
            직업정보제공사업 : j1516020230009 <br />
            대표전화 : 02-558-1195 ㅣ 경기도 성남시 분당구 성남대로916번길 11,
            4층 416호(야탑동)
          </p>
          <p className="mt-2 text-gray-500">
            Copyright © OneDream. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
